<template>
  <v-card class="px-6">
    <v-card-title class="d-flex justify-center">
      <v-spacer />

      <span> Detalhes do funcionário</span>

      <v-spacer />

      <v-icon @click="closeModal">
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <Separator label="Filtrar dados" />

    <div style="display: flex; align-items: center; justify-content: flex-end;">
      <v-tooltip
        v-if="!isExpand"
        left
        color="info"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="isExpand = !isExpand"
          >
            <v-icon
              color="info"
              v-bind="attrs"
              v-on="on"
              @click="isExpand = !isExpand"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>

      <v-tooltip
        v-else
        v-show="isExpand"
        left
        color="success"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
          >
            <v-icon
              v-show="!isLoading"
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="sendModalData()"
            >
              {{ icons.mdiContentSave }}
            </v-icon>
            <v-progress-circular
              v-show="isLoading"
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </template>

        <span v-if="!isLoading">Salvar</span>
        <!-- <span>Salvar</span> -->
      </v-tooltip>
    </div>

    <v-card-text class="mt-10 px-0">
      <v-row>
        <v-col span="12">
          <v-text-field
            v-model="name"
            label="Nome"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="company"
            label="Loja"
            readonly
            outlined
            dense
          />

          <v-text-field
            v-model="sector"
            label="Setor"
            readonly
            outlined
            dense
          />
        </v-col>

        <v-col span="12">
          <v-text-field
            v-model="codeSelf"
            label="Cod.Self"
            type="number"
            outlined
            dense
          >
          </v-text-field>

          <v-text-field
            v-model="cloudId"
            label="Cloud ID"
            readonly
            outlined
            dense
          />

          <v-text-field
            v-model="occupation"
            label="Função"
            readonly
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-divider class="mb-8"></v-divider>

      <v-expand-transition>
        <v-card v-show="isExpand">
          <v-form ref="form">
            <v-row>
              <v-col span="12">
                <v-autocomplete
                  v-model="selectedOccupationId"
                  :items="occupationAndSectors"
                  item-value="id"
                  item-text="occupation.nameWithSector"
                  label="Alterar função"
                  class="mt-2"
                  clearable
                  outlined
                  dense
                />

                <v-switch
                  v-model="data.active"
                  label="Funcionário Ativo"
                  color="success"
                  class="ml-2 mt-0"
                  inset
                />
              </v-col>

              <v-col span="12">
                <v-autocomplete
                  v-model="selectedCompanyId"
                  :items="companies"
                  item-value="id"
                  item-text="fantasy_name"
                  label="Alterar loja"
                  class="mt-2"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-expand-transition>

      <Separator label="Controle de estabilidade" />

      <div class="mt-6">
        <span
          class="d-flex"
          style="color: #fff"
        >Deseja <p style="color: #C48EFF; margin-left: 4px; margin-right: 4px;">atribuir estabilidade</p> para o funcionário?</span>
      </div>

      <div>
        <div class="d-flex items-center">
          <v-icon
            color="#fff"
            v-bind="attrs"
            v-on="on"
          >
            {{ icons.mdiPlus }}
          </v-icon>

          <span
            class="add-stabilite"
            style="color: #fff"
            @click="isAddStability = true"
          >Atribuir estabilidade </span>
        </div>

        <div
          v-show="isAddStability"
          style="display: flex; flex-direction: column;"
        >
          <div style="display: flex; gap: 10px; margin-top: 16px;">
            <v-autocomplete
              v-model="stability"
              :items="listStability"
              label="Tipo de estabilidade"
              class=""
              clearable
              outlined
              dense
            >
            </v-autocomplete>
            <v-menu
              ref="menuStart"
              v-model="menuStart"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedStartDate"
                  label="Data final"
                  style="height: 62px"
                  dense
                  outlined
                  readonly
                  v-bind="attrs"
                  :append-icon="icons.mdiCalendar"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
                @change="(date) => { $refs.menuStart.save(date); startDate = date; }"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menuStart = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuStart.save(startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menuEnd"
              v-model="menuEnd"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEndDate"
                  label="Data final"
                  style="height: 62px"
                  dense
                  outlined
                  readonly
                  v-bind="attrs"
                  :append-icon="icons.mdiCalendar"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                @change="(date) => { $refs.menuEnd.save(date); endDate = date; }"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menuEnd = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuEnd.save(endDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>

          <div style="display: flex; gap: 10px;">
            <v-btn
              outlined
              color="success"
              @click="addStability()"
            >
              <v-progress-circular
                v-if="isLoadingAddStability"
                color="white"
                indeterminate
              >
              </v-progress-circular>
              <div v-else>
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
                ADICIONAR
              </div>
            </v-btn>
            <v-btn
              outlined
              color="error"
              @click="isAddStability = false"
            >
              CANCELAR
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <v-timeline
          v-for="stabilityItem in dataStability"
          :key="stabilityItem.id"
          dense
          class="timeline-custom-dense timeline-custom-dots"
        >
          <v-timeline-item
            small
            color="info"
            class=""
          >
            <div style="display: flex; margin-top: -10px; margin-left: -10px;">
              <v-avatar
                :key="stabilityItem.created_by_photo ? stabilityItem.created_by_photo : ''"
                size="45px"
                v-bind="attrs"
                color="primary"
                class="v-avatar-light-bg primary--text"
                v-on="on"
              >
                <v-img :src="stabilityItem.created_by_photo ? stabilityItem.created_by_photo : ''"></v-img>
              </v-avatar>
              <div
                class="d-flex align-start mb-4 ml-2 flex-column"
                @mouseover="isShowActions = stabilityItem.id"
                @mouseleave="isShowActions = null"
              >
                <div
                  class="d-flex justify-space-between"
                >
                  <p
                    class="font-weight-semibold mb-1"
                    style="color:#fff"
                  >
                    {{ stabilityItem.created_by_name }}
                  </p>
                  <p style="margin-left: 10px; color:#fff; font-weight: 400;">
                    atribuiu uma estabilidade
                  </p>

                  <div
                    v-if="isShowActions === stabilityItem.id"
                    style="display: flex; margin-left: 12px;"
                  >
                    <v-icon
                      style="margin-right: 8px;"
                      color="info"
                      size="20"
                      @click="handleOpenModalEditStability(stabilityItem.id)"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                    <v-icon
                      color="error"
                      size="20"
                      @click="handleDeleteStability(stabilityItem.id)"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </div>
                </div>
                <span style="color:#fff">{{ dateFormat(stabilityItem.created_at) }}</span>
              </div>
            </div>

            <div style="margin-bottom: 50px;">
              <div style="display: flex; align-items: center; margin-bottom: 10px;">
                <v-icon :color="stabilityItem.type === 'CIPA' ? 'success' : stabilityItem.type === 'LICENÇA MATERNIDADE' ? 'purple' : 'error'">
                  {{
                    stabilityItem.type === 'CIPA' ? icons.mdiPlusCircleOutline : stabilityItem.type === 'LICENÇA MATERNIDADE' ? icons.mdiMotherHeart : icons.mdiHospital
                  }}
                </v-icon>
                <span style="color:#fff; margin-left: 8px;">{{ stabilityItem.type }}</span>
              </div>

              <div style="display: flex; flex-direction: column;">
                <span style="font-size: 12px; color: #fff;">PERIODO: DE {{ dateFormat(stabilityItem.start_date) }} ATÉ {{ dateFormat(stabilityItem.end_date) }}</span>

                <div :class="{ 'bg-green': stabilityItem.status === 'No Período', 'bg-red': stabilityItem.status !== 'No Período' }">
                  <v-icon
                    size="20"
                    :color="stabilityItem.status === 'No Período' ? 'success' : 'error'"
                  >
                    {{ icons.mdiClockTimeEightOutline }}
                  </v-icon>
                  {{ stabilityItem.status }}
                </div>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>

      <!-- <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div> -->
    </v-card-text>

    <v-dialog
      v-model="showEditStability"
      width="700px"
    >
      <edit-stability
        :key="stabilityId"
        :data-id="stabilityId"
        @updatedTable="handleGetStability()"
        @close="showEditStability = false"
      ></edit-stability>
    </v-dialog>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar,
  mdiClockTimeEightOutline,
  mdiClose,
  mdiContentSave,
  mdiDeleteOutline,
  mdiHospital,
  mdiMotherHeart,
  mdiPencil, mdiPlus, mdiPlusCircleOutline,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import moment from 'moment'
import 'moment/locale/pt-br'
import EditStability from './EditStability.vue'

export default {
  components: {
    Separator,
    EditStability,
  },
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },

    occupationAndSectors: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      stabilityId: '',
      showEditStability: false,
      idUserProp: this.data.id,
      idDelete: '',
      isLoadingDelete: false,
      isShowActions: false,
      isLoadingAddStability: false,
      dataStability: [],
      menuStart: false,
      menuEnd: false,
      startDate: '',
      endDate: '',
      stability: '',
      isAddStability: false,
      companies: [],
      listStability: ['CIPA', 'LICENÇA MATERNIDADE', 'ACIDENTE DE TRABALHO'],
      selectedCompanyId: '',
      selectedOccupationId: '',

      isExpand: false,
      isLoading: false,

      icons: {
        mdiPencil,
        mdiClose,
        mdiPlus,
        mdiCalendar,
        mdiContentSave,
        mdiPlusCircleOutline,
        mdiMotherHeart,
        mdiHospital,
        mdiClockTimeEightOutline,
        mdiDeleteOutline,
      },
    }
  },

  computed: {
    name() {
      const name = this.data?.name ?? ''

      return name
    },

    company() {
      const companyName = this.data?.company?.fantasy_name ?? 'Não atribuído'

      return companyName
    },

    codeSelf: {
      get() {
        const code = this.data?.cod_self ?? 'Não atribuído'

        return code
      },

      set(value) {
        this.data.cod_self = Number(value)
      },
    },

    cloudId: {
      get() {
        const cloudId = this.data?.cloud_id ?? 'Não atribuído'

        return cloudId
      },

      set(value) {
        this.data.cloud_id = value
      },
    },

    occupation() {
      const occupation = this.data?.occupation_sector?.occupation?.name ?? 'Não atribuído'

      return occupation
    },

    sector() {
      const sector = this.data?.occupation_sector?.sector?.name ?? 'Não atribuído'

      return sector
    },

    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
  },

  created() {
    this.companies = localStorageSlim.get('listCompanies', { decrypt: true })

    this.handleGetStability()
  },

  methods: {
    handleOpenModalEditStability(id) {
      this.stabilityId = id
      this.showEditStability = true
    },
    async handleDeleteStability(id) {
      const confirmed = await this.confirmPost('Aviso!', 'Tem certeza que deseja continuar')

      if (confirmed) {
        try {
          this.isLoadingDelete = true
          await axiosIns.delete(`/api/v1/records/user_stability/destroy/${id}`)

          this.showMessage({ title: 'Sucesso', text: 'Estabilidade excluída', icon: 'success' })
        } catch (error) {
          this.showErrorMessage(error)
        } finally {
          this.isLoadingDelete = false
          this.handleGetStability()
        }
      }
    },
    async handleGetStability() {
      try {
        const result = await axiosIns.get(`/api/v1/records/user_stability/get_stabilities_by_user/${this.idUserProp}`)

        this.dataStability = result.data.data
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async addStability() {
      this.isLoadingAddStability = true

      const body = {
        user_id: this.idUserProp,
        type: this.stability,
        start_date: this.startDate,
        end_date: this.endDate,
      }

      try {
        await axiosIns.post('/api/v1/records/user_stability/store', body)

        this.showMessage({ title: 'Sucesso', text: 'Estabilidade atribuída', icon: 'success' })

        // this.updatedTable()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingAddStability = false

        this.stability = ''
        this.startDate = ''
        this.endDate = ''
        this.isAddStability = false
        this.handleGetStability()
      }
    },
    async sendModalData() {
      this.isLoading = true
      const { selectedOccupationId, selectedCompanyId, data } = this

      const body = {
        active: data.active,
        cod_self: data.cod_self,
      }

      if (selectedCompanyId) {
        body.companies_id = selectedCompanyId
      }

      if (selectedOccupationId) {
        body.occupations_sectors_id = selectedOccupationId
      }

      await axiosIns
        .put(`api/v1/records/user/update/${this.data.id}`, body)
        .then(() => {
          this.showMessage({ title: 'Sucesso', text: 'Registro enviado com sucesso', icon: 'success' })
          this.updatedTable()
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isExpand = false
          this.isLoading = false
          this.closeModal()
          this.resetForm()
        })
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>

<style>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.add-stabilite:hover{
  text-decoration: underline;
  cursor: pointer;
}

.bg-green{
  background: #56CA001A;
  width: 110px;
  border-radius: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #56CA00;
  margin-top: 8px;
  gap: 3px;
}

.bg-red{
  background: #FF4C511A;
  width: 90px;
  border-radius: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF4C51;
  margin-top: 8px;
  gap: 3px;
}
</style>
